import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import G from './G';


import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);

  useEffect(() => {
    fetch('/api/location')
      .then(response => response.json())
      .then(data => {
        setCountry(data.country);
        setRegion(regionNames.of(data.country.toUpperCase()));
        setCity(data.city);
      })
      .catch(error => console.error('Fetch error:', error));
  }, []);

  const imageUrls = [
    'https://i.ibb.co/hHXq4jN/cropped-photo-2024-04-30-00-58-06.jpg',
    'https://i.ibb.co/rkC9yF8/cropped-photo-2024-04-30-00-58-04.jpg',
    'https://i.ibb.co/6BK8vGj/cropped-photo-2024-04-30-00-58-03.jpg',
    'https://i.ibb.co/ncYp7bB/cropped-photo-2024-04-30-00-58-01.jpg',
    'https://i.ibb.co/rwDpVxW/cropped-photo-2024-04-30-00-58-00.jpg',
    'https://i.ibb.co/qgbTvdc/cropped-photo-2024-04-30-00-57-58.jpg',
    'https://i.ibb.co/sFZjPCq/cropped-photo-2024-04-30-00-57-57.jpg',
    'https://i.ibb.co/Z62bhj6/cropped-photo-2024-04-30-00-57-56.jpg',
    'https://i.ibb.co/FqFkX7f/cropped-photo-2024-04-30-00-57-54.jpg',
    'https://i.ibb.co/LPhwLhD/cropped-photo-2024-04-30-00-57-53.jpg',
    'https://i.ibb.co/BzwzqV3/cropped-photo-2024-04-30-00-57-49.jpg',
    'https://i.ibb.co/4KJ8x9K/cropped-photo-2024-04-30-00-57-51.jpg'
  ];

  return (
    <Router>
    <Routes>
      <Route path="/" element={
    <div className="container">
    <img 
      src="https://assets.cdn.filesafe.space/CJkDD96k2jOfuywnYhgP/media/65a51f792aaf1b036608af13.png" 
      alt="Header Image"
      className="header-image"
    />
    <Swiper
      effect={'coverflow'}
      grabCursor={true}
      centeredSlides={true}
      loop={true}
      slidesPerView={'2'}
      coverflowEffect={{
      rotate: 15,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
     }}
    autoplay={{ // Corrected from autoPlay to autoplay
      delay: 2000,
      disableOnInteraction: false,
    }}
    breakpoints={{
      // Screens smaller than 640px
      640: {
        slidesPerView: 2,
      },
      // Larger screens
      640: {
        slidesPerView: 3, // or whatever number you prefer
      },
    }}
    modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
    className="swiper-container"
  >
    {imageUrls.map(url => (
      <SwiperSlide key={url} className="swiper-slide">
        <img src={url} alt="slide_image"/>
      </SwiperSlide>
    ))}
      <div className="slider-controler">
        <div className="swiper-pagination"></div>
      </div>
    </Swiper>

    <div className='textContainer'>
      <img src='https://i.ibb.co/zRMRK8D/cropped-photo-2024-04-30-00-57-58-modified.png'/>
      <section>
          <div className='flexRow'>Natalia 💕 <div className='buttonStyling'>Online Now</div></div>
          <div className='flexRow'>
            <span>
            Your favorite 19yo Student currently in {city}, {region}   {country && <img 
                src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`} 
                alt={`${country} Flag`}
                style={{ width: '20px', height: '15px', verticalAlign: 'middle' }}
              />} 
          
              
            </span>
          </div>


      </section>
    </div>

    <a href="https://onlyfans.com/nataliarioss/c4" id="customButton">
      Send me a message
    </a>

    <p className="exclusiveContent">
      Exclusive content 💖 75% Off for a limited time only! $3 for the next person ONLY<br/><br/>
      Looking for content partners, DM me on OnlyFans to film w/ me 🎥💓
    </p>
    <Analytics/>
  </div>
          } />
          <Route path="/I-Am-Waiting-For-You/onlyfans.com/nataliarioss" element={<G />} />
          </Routes>
          </Router>
  );
}

export default App;
